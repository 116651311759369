<template>
  <div class="system-status">
    <div class="container">
      <div class="header">
        <h1>Yun—v1.0</h1>
      </div>
      
      <div class="nav-menu">
        <router-link to="/" class="nav-item">Home</router-link>
        <router-link to="/feed" class="nav-item">Feed</router-link>
        <router-link to="/system-status" class="nav-item">System Status</router-link>
      </div>

      <div class="content">
        <div class="status-panel">
          <div class="status-item">
            <span class="status-label">System Status:</span>
            <span class="status-value" :class="{ 'status-online': isOnline }">
              {{ systemStatus }}
            </span>
          </div>
          <div class="status-item">
            <span class="status-label">Version:</span>
            <span class="status-value">1.0</span>
          </div>
          <div class="status-item">
            <span class="status-label">Last Updated:</span>
            <span class="status-value">{{ lastUpdated }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SystemStatus',
  data() {
    return {
      isOnline: true,
      systemStatus: 'Online',
      lastUpdated: new Date().toLocaleString()
    }
  }
}
</script>

<style scoped>
.system-status {
  min-height: 100vh;
}

.header h1 {
  font-size: 34px;
  font-weight: normal;
  margin-bottom: 30px;
}

.nav-menu {
  margin-bottom: 60px;
}

.nav-item {
  margin-right: 30px;
  color: #ffffff;
  text-decoration: none;
  font-size: 16px;
  opacity: 0.8;
  transition: opacity 0.3s;
}

.nav-item:hover {
  opacity: 1;
}

.status-panel {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 4px;
  padding: 30px;
}

.status-item {
  margin-bottom: 20px;
}

.status-item:last-child {
  margin-bottom: 0;
}

.status-label {
  font-weight: normal;
  margin-right: 15px;
  color: rgba(255, 255, 255, 0.6);
}

.status-value {
  color: #ffffff;
}

.status-online {
  color: #4CAF50;
}
</style> 